<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>

          <!-- first descricao -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="descricao"
              rules="required"
            >
              <b-form-group
                label="Descrição"
                label-for="v-descricao"
              >
                <b-form-input
                  id="v-descricao"
                  v-model="form.descricao"
                  :state="errors.length > 0 ? false:null"
                  placeholder="descrição"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- tipo Protocolo -->
          <b-col
            cols="12"
            sm="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Tipo Protocolo"
              rules="required"
            >
              <b-form-group
                label="Tipo Protocolo"
                label-for="v-tipoProtocolo"
              >

                <v-select
                  v-model="form.tipoProtocolo"
                  :reduce="(comboTipoProtocolo) => comboTipoProtocolo.id"
                  label="descricao"
                  :options="comboTipoProtocolo"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Data Início"
              rules="required"
            >
              <b-form-group
                label="Data Início"
                label-for="v-dataInicio"
              >
                <flat-pickr
                  v-model="form.dataInicio"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                />
                <small
                  data-cy="v-dataInicio-validate"
                  class="text-danger"
                >{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Data Término"
              rules=""
            >
              <b-form-group
                label="Data Término"
                label-for="v-dataFim"
              >
                <flat-pickr
                  v-model="form.dataTermino"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                />
                <small
                  data-cy="v-dataFim-validate"
                  class="text-danger"
                >{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="observacao"
              rules="required"
            >
              <b-form-group
                label="Nome da Empresa"
                label-for="v-registro"
              >
                <v-select
                  v-model="form.registro"
                  label="nome"
                  :filterable="false"
                  :options="comboRegistros"
                  :selected="form.registro"
                  @search="onSearch"
                ><template slot="no-options">
                    Pesquisa registro por nome, cpf ou matrícula
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small
                  data-cy="v-registro"
                  class="text-danger"
                >{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="my-2">
            <fieldset>
              <legend 
                style="font-size: 16px;"
                class="text-uppercase font-weight-bold"
              >
                Dados do Responsável da Empresa
              </legend>
              <hr>
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome do Responsável"
                    rules=""
                  >
                    <b-form-group
                      label="Nome do Responsável"
                      label-for="v-responavelNome"
                    >
                      <b-form-input
                        id="v-responavelNome"
                        v-model="form.responavelNome"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nome do Responsável"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="responavelCpf"
                    rules=""
                  >
                    <b-form-group
                      label="CPF"
                      label-for="v-responavelCpf"
                    >
                      <b-form-input
                        id="v-responavelCpf"
                        v-model="form.responavelCpf"
                        placeholder="responavelCpf"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="responavelRg"
                    rules=""
                  >
                    <b-form-group
                      label="RG"
                      label-for="v-responavelRg"
                    >
                      <b-form-input
                        id="v-responavelRg"
                        v-model="form.responavelRg"
                        placeholder="responavelRg"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!--Nacionalidade -->
                <b-col
                  cols="12"
                  lg="4"
                >
                  <b-form-group
                    label="Nacionalidade"
                    label-for="v-nacionalidade"
                  >

                    <v-select
                      v-model="form.responavelNacionalidade"
                      label="nome"
                      :options="comboNacionalidades"
                    />
                  </b-form-group>
                </b-col>

                <!--Estado Civil -->
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group
                    label="Estado Civil"
                    label-for="v-EstadoCivil"
                  >

                    <v-select
                      v-model="form.responavelEstadoCivil"
                      label="nome"
                      :options="comboEstadoCivil"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="qualificacao"
                    rules=""
                  >
                    <b-form-group
                      label="Qualificação"
                      label-for="v-qualificacao"
                    >
                      <b-form-input
                        id="v-qualificacao"
                        v-model="form.responavelQualificacao"
                        placeholder="qualificacao"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cargo"
                    rules=""
                  >
                    <b-form-group
                      label="Cargo"
                      label-for="v-cargo"
                    >
                      <b-form-input
                        id="v-cargo"
                        v-model="form.responavelCargo"
                        placeholder="cargo"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Profissão"
                    rules=""
                  >
                    <b-form-group
                      label="Profissão"
                      label-for="v-responavelProfissao"
                    >
                      <b-form-input
                        id="v-responavelProfissao"
                        v-model="form.responavelProfissao"
                        placeholder="responavelProfissao"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </fieldset>
          </b-col>

          <b-col
            cols="12"
            lg="8"
          >
            <validation-provider
              #default="{ errors }"
              name="responsavelTecnico"
              rules=""
            >
              <b-form-group
                label="Responsável Técnico"
                label-for="v-responsavelTecnico"
              >
                <b-form-input
                  id="v-responsavelTecnico"
                  v-model="form.responavelTecnico"
                  placeholder="responsavelTecnico"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <validation-provider
              #default="{ errors }"
              name="percentualDesconto"
              rules=""
            >
              <b-form-group
                label="Percentual Desconto (%)"
                label-for="v-percentualDesconto"
              >
                <b-form-input
                  id="v-percentualDesconto"
                  v-model="form.percentualDesconto"
                  type="number"
                  placeholder="percentualDesconto"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            v-if="form.id"
            cols="12"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="my-3" v-if="$route.params.id">
            <div v-if="showFormItem">
              <b-card title="Anexar Item">
                <b-form @submit.prevent>
                  <b-spinner
                    v-if="loading"
                    class="float-right"
                    label="Floated Right"
                  />
                  <b-row>
                    <!-- first name -->
                    <b-col cols="12">
                      <b-form-file
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-col>
                    <!-- submit and reset -->
                    <b-col
                      cols="12"
                      class="mt-3"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="success"
                        class="mr-1"
                        @click="save"
                      >
                        Enviar Arquivo
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        @click="cancelarDocumento"
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </div>

            <div v-else>
              <b-card title="Anexar Item">
                <vue-good-table
                  cols="12"
                  :columns="columns"
                  :rows="form.itemProtocolo"
                  :rtl="direction"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="(props.column.field === 'principal') || (props.column.field === 'whatsapp')">
                      <span>
                        {{ props.formattedRow[props.column.field] ? 'Sim' : 'Não' }}
                      </span>
                    </span>
                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item @click="inserirDocumento(props.row)">
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-50"
                            />
                            <span>Incluir</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="downloadDocument(props.row)">
                            <feather-icon
                              icon="DownloadIcon"
                              class="mr-50"
                            />
                            <span>Abrir</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteDocument(props.row)">
                            <feather-icon
                              icon="TrashIcon"
                              class="mr-50"
                            />
                            <span>Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Mostrando 1 a
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> de {{ props.total }} registros </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-card>
              </div>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BFormCheckbox,
  BSpinner, BPagination, BDropdown, BDropdownItem, BFormSelect, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('convenioModule', {
      convenio: state => state.convenio,
    }),
    ...mapState('combosModule', {
      comboTipoProtocolo: state => state.comboTipoProtocolo,
      comboNacionalidades: state => state.comboNacionalidades,
      comboEstadoCivil: state => state.comboEstadoCivil,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BSpinner,
    BPagination,
    vSelect,
    flatPickr,
    VueGoodTable,
    BDropdown, 
    BDropdownItem, 
    BFormSelect,
    BFormFile
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      titleForm: 'Cadastro de Convênio',
      required,
      form: {
        id: null,
        descricao: '',
        dataInicio: '',
        dataTermino: '',
        ativo: true,
        responavelNome: '',
        responavelCpf: '',
        responavelRg: '',
        responavelQualificacao: '',
        responavelEstadoCivil: {
          id: null,
          nome: null,
        },
        responavelNacionalidade: {
          id: null,
          nome: null
        },
        responavelProfissao: '',
        responavelCargo: '',
        responavelTecnico: '',
        registro: {
          id: null,
        },
        percentualDesconto: null,
        tipoProtocolo: null,
        itemProtocolo: []
      },
      comboRegistros: [],
      showFormItem: false,
      loading: false,
      showForm: false,
      file: null,
      listaDocumentacoes: [],
      columns: [
        {
          label: 'Descrição',
          field: 'item.descricao',
        },
        {
          label: 'Entregue',
          field: 'entregue',
          formatFn: this.formatColumBoolean,
        },
        {
          label: 'Ações',
          field: 'action',
        },
      ],
      pageLength: 10,
      dir: false,
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch('convenioModule/getById', this.$route.params.id).then(() => {
        this.form = JSON.parse(JSON.stringify(this.convenio))
        this.form.dataInicio = this.dataParaFormulario(this.form.dataInicio)
        this.form.dataTermino = this.dataParaFormulario(this.form.dataTermino)
      })
    }
    await this.$store.dispatch('combosModule/loadComboTipoProtocolo')
    await this.$store.dispatch('combosModule/loadComboNacionalidades')
    await this.$store.dispatch('combosModule/loadComboEstadoCivil')
  },
  methods: {
    inserirDocumento(data) {
      console.log('data', data)
      this.showFormItem = true
    },
    cancelarDocumento() {
      this.showFormItem = false
    },
    cancel() {
      if (this.$route.params.id) {
        this.$router.go(-1)
        return
      }
      this.$emit('cancelar')
      this.$store.dispatch('convenioModule/reset')
      this.cleanObjeto()
    },
    prepareDadosParaSalvar(obj) {
      const objSave = JSON.parse(JSON.stringify(obj))
      objSave.dataInicio = this.preparaDataParaSalvarEdicao(objSave.dataInicio)
      objSave.dataTermino = this.preparaDataParaSalvarEdicao(objSave.dataTermino)
      objSave.responavelEstadoCivil = objSave.responavelEstadoCivil.id
      objSave.responavelNacionalidade = objSave.responavelNacionalidade.id
      return objSave
    },
    save() {
      this.$refs.form.validate().then(success => {
        const obj = this.prepareDadosParaSalvar(this.form)
        if (success) {
          if (this.form.id) {
            this.$store.dispatch('convenioModule/edit', obj).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Editar', response.response.data.error)
                return
              }
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.cancel()
            })
          } else {
            this.$store.dispatch('convenioModule/insert', obj).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Inserir', response.response.data.error)
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cleanObjeto()
              this.$refs.form.reset()
            })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        id: null,
        descricao: '',
        dataInicio: '',
        dataTermino: '',
        ativo: true,
        responavelNome: '',
        responavelCpf: '',
        responavelRg: '',
        responavelQualificacao: '',
        responavelEstadoCivil: {
          id: null,
          nome: null,
        },
        responavelNacionalidade: {
          id: null,
          nome: null
        },
        responavelProfissao: '',
        responavelCargo: '',
        responavelTecnico: '',
        registro: {
          id: null,
        },
        percentualDesconto: 0,
        tipoProtocolo: null,
        itemProtocolo: []
      }
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'convenioModule/searchRegistrosPessoaJuridica',
        search,
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`,
        )
        return
      }

      if (registrosEncontrados.data.content.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboRegistros = registrosEncontrados.data.content
      loading(false)
    }, 350),
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
